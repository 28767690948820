
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import dayjs from "dayjs";
import { emptyToLine, splitThousands } from "@/utils/common";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import t from "@common/src/i18n/t";
import { Pageniation as Table, TableRowsSelect } from "@/utils/decorator";
const consult = namespace("consult");
@Component({
  components: { TableEnableFlag }
})
@Table("loadListData")
@TableRowsSelect({
  warningMsg: t("setting.select-spare-parts"),
  updateStatusFuncName: "confirmUpdateStatus",
  tableRefKey: "table"
})
export default class ConsultCategoryList extends Vue {
  @consult.Action queryContentClassPage;
  @consult.Action changeClassOrder;
  @consult.Action delContClass;
  @consult.Action repeatClassName;
  @consult.Action updateContentClass;
  @consult.Action insertContentClass;

  dayjs = dayjs;
  searchForm = {
    className: ""
  };
  splitThousands = splitThousands;
  emptyToLine = emptyToLine;
  insertForm: any = {
    className: ""
  };
  dialogFormVisible: boolean = false;
  contentClassList: any[] = [];
  total: number = 0;
  get searchList() {
    return [
      {
        label: this.$t("v210831.classification-name"),
        type: "input",
        value: "",
        prop: "className"
      }
    ];
  }
  categoryList: any[] = [];
  brandList: any[] = [];
  created() {
    this.init();
  }
  async init() {
    this.loadListData();
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.queryContentClassPage({
      ...this.searchForm,
      pageNum: 1,
      pageSize: 999
    }).then(data => {
      this.contentClassList = data.data.list;
      this.total = data.data.total;
      return data;
    });
  }
  @TableRowsSelect.updateMethod
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.prop === "categoryCode" ? item.value[item.value.length - 1] : item.value;
      }
    });
    console.log(this.searchForm);
    this.loadListData();
  }
  // 排序
  upOne(scope, num) {
    if (this.searchForm.className !== "") {
      this.$message.warning(t("v210831.query-status-does"));
      return;
    }
    if (num === 1) {
      return;
    }
    let params = {
      currentClassCode: scope.row.classCode,
      operateType: "4"
    };
    this.sortArrList(params);
  }
  upTop(scope, num) {
    if (this.searchForm.className !== "") {
      this.$message.warning(t("v210831.query-status-does"));
      return;
    }
    if (num === 1) {
      return;
    }
    let params = {
      currentClassCode: scope.row.classCode,
      operateType: "1"
    };
    this.sortArrList(params);
  }
  downOne(scope, num) {
    if (this.searchForm.className !== "") {
      this.$message.warning(t("v210831.query-status-does"));
      return;
    }
    if (num === this.total) {
      return;
    }
    let params = {
      currentClassCode: scope.row.classCode,
      operateType: "5"
    };
    this.sortArrList(params);
  }
  downBottom(scope, num) {
    if (this.searchForm.className !== "") {
      this.$message.warning(t("v210831.query-status-does"));
      return;
    }
    if (num === this.total) {
      return;
    }
    let params = {
      currentClassCode: scope.row.classCode,
      operateType: "3"
    };
    this.sortArrList(params);
  }
  sortArrList(params) {
    this.changeClassOrder(params).then(() => {
      this.$message.success(t("v210831.sorting-succeeded"));
      this.loadListData();
    });
  }
  // 删除
  handleDelete(row) {
    this.delContClass({ classCode: row.classCode }).then(() => {
      this.$message.success(t("v210831.delete-succeeded"));
      this.loadListData();
    });
  }
  // 新增
  handleInsert() {
    if (this.insertForm.className == "") {
      return;
    }
    this.repeatClassName({ className: this.insertForm.className }).then(res => {
      console.log(res);
      if (res.data.repeat) {
        this.$message.warning(t("v210831.classification-name-already"));
      } else {
        this.insertContentClass({ className: this.insertForm.className }).then(() => {
          this.loadListData();
          this.dialogFormVisible = false;
          this.$message.success(t("v210831.new-successfully"));
          this.insertForm.className = "";
        });
      }
    });
  }
}
